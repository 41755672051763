<template>
  <div class="dashboard-background h-full">
    <in-page-menu />
    <div class="mx-4 mx-lg-8 mx-md-6 pt-20">
      <template>
        <v-row class="align-center d-flex">
          <v-col
            class="align-center d-flex justify-md-end ml-auto"
            cols="12"
            md="9"
          >
            <v-btn
              :disabled="loading"
              @click="fetchData"
              class="mt-0 py-2 mr-4"
              depressed
              text
            >
              <v-icon> mdi-reload </v-icon>
            </v-btn>
            <v-text-field
              :placeholder="getTranslation('animalsPages.search')"
              class="mb-0 rounded-lg animal-search"
              dense
              hide-details
              outlined
              prepend-inner-icon="mdi-magnify"
              v-model.trim="searchTerm"
            />
            <v-switch
              :disabled="loading"
              @change="fetchData"
              class="mt-0 py-2 mx-4 display-mode"
              color="primary"
              hide-details
              label="Display all"
              v-model="displayAll"
            />
            <v-btn
              @click="exportToCSV"
              class="normal-case rounded-lg light-blue-button ml-3"
              text
            >
              <v-icon class="mr-1"> mdi-open-in-new </v-icon>
              CSV
            </v-btn>
            <v-btn
              @click="openEditAnimalDialog()"
              class="normal-case rounded-lg primary-button ml-3"
              text
            >
              <v-icon class="mr-1" primary="true"> mdi-plus </v-icon>
              {{ getTranslation("animalsPages.new") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <div
        :class="{ 'd-flex': $vuetify.breakpoint.lgAndUp }"
        class="gap-4 mt-2"
      >
        <div>
          <div v-if="$vuetify.breakpoint.lgAndUp">
            <v-card class="light-elevation rounded-lg" elevation="0">
              <v-navigation-drawer floating permanent>
                <v-list
                  :dense="$vuetify.breakpoint.mdAndDown"
                  class="data-types-list"
                >
                  <v-list-item-group mandatory v-model="activeEditionIndex">
                    <v-list-item
                      :key="item.title"
                      :value="$index"
                      link
                      v-for="(item, $index) in dataTypes"
                    >
                      <v-list-item-icon>
                        <v-icon
                          :primary="
                            !$vuetify.theme.dark &&
                            activeEditionIndex === $index
                          "
                        >
                          {{ item.icon }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <div class="font-weight-bold">{{ item.title }}</div>
                          <span
                            class="caption grey--text"
                            :class="
                              $vuetify.theme.dark
                                ? 'text--lighten-1'
                                : 'text--darken-1'
                            "
                          >
                            Edit {{ item.title }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-navigation-drawer>
            </v-card>
          </div>
          <div class="mt-6" v-else>
            <v-select
              :items="dataTypesSelect"
              item-text="title"
              item-value="index"
              label="Active edition"
              v-model="activeEditionIndex"
            />
          </div>
        </div>
        <div class="flex-1 overflow-x-auto">
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="filteredObjects"
            class="dashboard-data-table mb-6"
            hide-default-footer
            ref="table"
            show-select
            sort-by="orderVT"
            v-model="selected"
          >
            <template #top="{ options, pagination, updateOptions }">
              <v-data-footer
                :items-per-page-text="
                  getTranslation('animalsPages.itemsPerPage')
                "
                :options="options"
                :pagination="pagination"
                @update:options="updateOptions"
              />
            </template>
            <template #body="props">
              <draggable
                :list="props.items"
                @end="reorderRow"
                handle=".draggable"
                tag="tbody"
                v-if="$refs.table"
              >
                <v-nodes :vnodes="$refs.table.genItems(props.items, props)" />
              </draggable>
            </template>
            <template v-slot:item.gender="{ item }">
              <td>
                {{
                  item.gender
                    ? getLabelTranslation(`enums.genders.${item.gender}`)
                    : null
                }}
              </td>
            </template>
            <template v-slot:item.pregCheck="{ item }">
              <td>
                <v-simple-checkbox
                  :disabled="item.archived"
                  @input="togglePregCheck(item, $event)"
                  v-model="item.pregCheck"
                />
              </td>
            </template>
            <template v-slot:item.weanOrigin="{ item }">
              <td>
                <v-simple-checkbox
                  :disabled="item.archived"
                  @input="toggleWeanOrigin(item, $event)"
                  v-model="item.weanOrigin"
                />
              </td>
            </template>
            <template v-slot:item.intakeLocation="{ item }">
              <td>
                <v-simple-checkbox
                  :disabled="item.archived"
                  @input="toggleIntakeLocation(item, $event)"
                  v-model="item.intakeLocation"
                />
              </td>
            </template>
            <template v-slot:item.weanSex="{ item }">
              <td>
                <v-simple-checkbox
                  :disabled="item.archived"
                  @input="toggleWeanSex(item, $event)"
                  v-model="item.weanSex"
                />
              </td>
            </template>
            <template v-slot:item.archiveAction="{ item }">
              <td class="text-right">
                <div @click="toggleArchive(item)" class="archive-switch">
                  <v-switch
                    :false-value="true"
                    :true-value="false"
                    class="mt-0 pt-0"
                    color="rgb(41, 80, 143)"
                    dense
                    hide-details
                    v-model="item.archived"
                  />
                </div>
              </td>
            </template>
            <template v-slot:item.alias="{ item }">
              <td :id="item.id" class="pl-0">
                <div class="d-flex align-center">
                  <v-btn class="draggable mr-2" icon v-if="!searchTerm" x-small>
                    <v-icon>mdi-arrow-all</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="!item.editable"
                    @click="openEditAnimalDialog(item)"
                    class="primary-button rounded-lg mr-2"
                    icon
                    small
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    :href="`/couchdb/_utils/#database/organization-${getOrganizationID}/${item.id}`"
                    class="light-blue-button normal-case rounded-lg px-1"
                    target="_blank"
                    text
                  >
                    <v-icon class="mr-1">mdi-open-in-new</v-icon>
                    DB
                  </v-btn>
                  <span class="font-weight-medium pl-2">{{ item.alias }}</span>
                </div>
              </td>
            </template>
            <template v-slot:item.expirationDate="{ item }">
              <td>
                {{
                  $utils.renderValueAs(item.expirationDate, "datetime", false)
                }}
              </td>
            </template>
            <template v-slot:item.purchaseDate="{ item }">
              <td>
                {{ $utils.renderValueAs(item.purchaseDate, "datetime", false) }}
              </td>
            </template>
            <template v-slot:item.pregOrWeanMedication="{ item }">
              <td class="text-right">
                <div
                  @click="toggleAllowOnPregOrWean(item)"
                  class="archive-switch"
                >
                  <v-switch
                    class="mt-0 pt-0"
                    color="rgb(41, 80, 143)"
                    dense
                    hide-details
                    v-model="item.pregOrWeanMedication"
                  />
                </div>
              </td>
            </template>
            <template v-slot:header.archiveAction="{ header }">
              <div class="text-right pr-1">
                {{ header.text }}
              </div>
            </template>
            <template #no-data>
              <div class="no-data-container opacity-70 mx-auto py-6 px-3">
                <div class="mb-6 h6">No data available</div>
                <NoData class="w-full h-fit" />
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <EditAnimalDialog
      :data-type="activeDataType"
      :headers="headers"
      :herd-meta="herdMeta"
      @saved="fetchData"
      ref="editAnimalDialog"
    />
    <MedicationDialog
      :herd-meta="herdMeta"
      :on-create="onCreate"
      @saved="fetchData"
      ref="medicationDialog"
    />
    <PastureAndPenDialog
      :herd-meta="herdMeta"
      :on-create="onCreate"
      @saved="fetchData"
      ref="pastureAndPenDialog"
    />
    <SexDialog
      :herd-meta="herdMeta"
      :on-create="onCreate"
      @saved="fetchData"
      ref="sexDialog"
    />
    <TagDialog
      :herd-meta="herdMeta"
      :on-create="onCreate"
      @saved="fetchData"
      ref="tagDialog"
    />
  </div>
</template>

<script>
import { EventBus } from "../mixins/Config";
import { IsSuperAdmin } from "../utils/functions/auth";
import { mapGetters } from "vuex";
import { toBoolean } from "@/utils/functions/common";
import EditAnimalDialog from "../components/dialogs/EditAnimalDialog.vue";
import MedicationDialog from "../components/dialogs/MedicationDialog.vue";
import NoData from "../components/icons/NoData.vue";
import PastureAndPenDialog from "../components/dialogs/PastureAndPenDialog.vue";
import SexDialog from "../components/dialogs/SexDialog.vue";
import TagDialog from "../components/dialogs/TagDialog.vue";
import TranslationMixin from "../mixins/Translations";

export default {
  name: "AnimalsLists",
  metaInfo: { title: "Edit" },
  components: {
    EditAnimalDialog,
    MedicationDialog,
    NoData,
    PastureAndPenDialog,
    SexDialog,
    TagDialog,
    VNodes: { functional: true, render: (h, ctx) => ctx.props.vnodes },
  },
  mixins: [TranslationMixin],
  data() {
    return {
      activeEditionIndex: 0,
      dataTypes: [
        {
          fetch: this.fetchColors,
          icon: "$paintBrush",
          saveFunction: this.saveColor,
          title: this.getLabelTranslation("colors"),
          toggleArchive: this.toggleArchiveColor,
        },
        {
          fetch: this.fetchHerds,
          icon: "$cowCalf",
          saveFunction: this.saveHerd,
          title: this.getLabelTranslation("Herds"),
          toggleArchive: this.toggleArchiveHerd,
        },
        {
          customRefDialog: "medicationDialog",
          fetch: this.fetchMedications,
          icon: "$medicine",
          title: this.getLabelTranslation("Medications"),
          toggleArchive: this.toggleArchiveMedication,
        },
        {
          fetch: this.fetchMouthScores,
          icon: "mdi-tooth",
          saveFunction: this.saveMouthScore,
          title: this.getLabelTranslation("Mouth Scores"),
          toggleArchive: this.toggleArchiveMouthScore,
        },
        {
          fetch: this.fetchOrigins,
          icon: "$pedigree",
          saveFunction: this.saveOrigin,
          title: this.getLabelTranslation("origins"),
          toggleArchive: this.toggleArchiveOrigin,
        },
        {
          customRefDialog: "pastureAndPenDialog",
          fetch: this.fetchPasturesAndPens,
          icon: "$pastureOrPen",
          title: this.getLabelTranslation("Pastures & Pens"),
          toggleArchive: this.toggleArchivePasturePen,
        },
        {
          customRefDialog: "sexDialog",
          fetch: this.fetchSexes,
          icon: "mdi-gender-male-female",
          saveFunction: this.saveSex,
          title: this.getLabelTranslation("sexes"),
          toggleArchive: this.toggleArchiveSex,
          type: "Sex",
        },
        {
          customRefDialog: "tagDialog",
          fetch: this.fetchTags,
          icon: "$eidTagFS",
          title: this.getLabelTranslation("Tags"),
          toggleArchive: this.toggleArchiveTag,
        },
      ],
      displayAll: false,
      headers: [],
      loading: false,
      objects: [],
      searchTerm: "",
      selected: [],
      timeReference: new Date("2000-01-01").getTime(),
    };
  },
  watch: {
    activeEditionIndex() {
      this.objects = [];
      this.searchTerm = "";
      this.fetchData();
    },
  },
  created: async function () {
    this.herdMeta = this.$herdMeta;
    this.init();
  },
  computed: {
    ...mapGetters({
      getOrganizationID: "Organization/getOrganizationID",
    }),
    activeDataType: function () {
      return this.dataTypes[this.activeEditionIndex];
    },
    filteredObjects: function () {
      if (!this.searchTerm) return this.objects;

      return this.objects.filter((object) =>
        (object.alias || "").toLowerCase().includes(this.searchTerm)
      );
    },
    isSuperAdmin: function () {
      return IsSuperAdmin();
    },
    dataTypesSelect: function () {
      return this.dataTypes.map((dataType, index) => ({ index, ...dataType }));
    },
  },
  methods: {
    init: function () {
      this.loading = true;
      this.$nextTick(() => {
        this.herdMeta.loaded.done(this.fetchData);
      });
    },
    onCreate() {
      this.init();
    },
    async fetchData() {
      this.loading = true;
      await this.herdMeta.reload();
      await this.activeDataType.fetch();
      if (!this.headers.some((header) => header && header.id === "enable"))
        this.headers.push({
          id: "enable",
          sortable: false,
          text: this.getLabelTranslation("Enable"),
          value: "archiveAction",
        });
      this.loading = false;
    },
    async fetchColors() {
      this.headers = [
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("Name"),
          value: "alias",
        },
      ];
      const response = await this.herdMeta.getMetaColorsAsync(
        true,
        !this.displayAll,
        false
      );
      response.forEach(async (row, index) => {
        row.alias = row.color;
        row.editable = this.getAllowedEdition(row);
        row.archived = toBoolean(row.archived);
      });
      this.objects = response;
    },
    async saveColor(object) {
      if (object.id) {
        const data = { id: object.id, color: object.alias };
        await this.herdMeta.update("colors", data);
      } else {
        const data = { id: "", color: object.alias };
        await this.herdMeta.create("colors", data, "color");
        this.init();
      }
    },
    toggleArchiveColor(row) {
      const data = {
        id: row.id,
        archived: !row.archived,
      };
      return this.herdMeta.update("colors", data);
    },
    toggleAllowOnPregOrWean(row) {
      const data = {
        id: row.id,
        pregOrWeanMedication: !row.pregOrWeanMedication,
      };
      return this.herdMeta.update("vaccinations", data);
    },
    async fetchHerds() {
      this.headers = [
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("Name"),
          value: "alias",
        },
      ];
      const response = await this.herdMeta.getMetaHerdsAsync(
        true,
        !this.displayAll,
        false
      );
      response.forEach(async (row, index) => {
        row.alias = row.name;
        row.editable = this.getAllowedEdition(row);
        row.archived = toBoolean(row.archived);
      });
      this.objects = response;
    },
    async saveHerd(object) {
      if (object.id) {
        const data = { id: object.id, name: object.alias };
        await this.herdMeta.update("herds", data);
      } else {
        const data = { id: "", name: object.alias };
        await this.herdMeta.create("herds", data, "herd");
        this.init();
      }
    },
    toggleArchiveHerd(row) {
      const data = {
        id: row.id,
        archived: !row.archived,
      };
      return this.herdMeta.update("herds", data);
    },
    async fetchMedications() {
      this.headers = [
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("Name"),
          value: "alias",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("dosesInBottle"),
          value: "ccsPerBottle",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("pricePerBottle"),
          value: "bottlePrice",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("withdrawlTime"),
          value: "withdrawlTime",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("withdrawlTimeUnits"),
          value: "withdrawlTimeUnits",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("manufacturer"),
          value: "manufacturer",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("dose"),
          value: "dose",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("doseUnits"),
          value: "doseUnits",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("administrationMethod"),
          value: "administrationMethod",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("expirationDate"),
          value: "expirationDate",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("purchaseDate"),
          value: "purchaseDate",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("vaccType"),
          value: "vaccType",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("lotNumber"),
          value: "lotNumber",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("pregOrWeanMedication"),
          value: "pregOrWeanMedication",
        },
      ];
      const response = await this.herdMeta.getMetaVaccinationsAsync(
        true,
        !this.displayAll,
        false
      );
      response.forEach(async (row, index) => {
        row.alias = row.name;
        row.editable = true;
        row.archived = toBoolean(row.archived);
      });
      this.objects = response;
    },
    toggleArchiveMedication(row) {
      const data = {
        id: row.id,
        archived: !row.archived,
      };
      return this.herdMeta.update("vaccinations", data);
    },
    async fetchMouthScores() {
      this.headers = [
        {
          align: "start",
          sortable: false,
          text: "Mouth Score",
          value: "alias",
        },
        {
          align: "start",
          sortable: false,
          text: "Preg check",
          value: "pregCheck",
        },
      ];
      const response = await this.herdMeta.getMetaMouthScoresAsync(
        true,
        !this.displayAll,
        false
      );
      response.forEach(async (row, index) => {
        row.alias = row.mouthScore;
        row.editable = this.getAllowedEdition(row);
        row.archived = toBoolean(row.archived);
      });
      this.objects = response;
    },
    async saveMouthScore(object) {
      if (object.id) {
        const data = { id: object.id, mouthScore: object.alias };
        await this.herdMeta.update("mouthscores", data);
      } else {
        const data = {
          id: "mouthscore_" + this.$utils.guid(),
          mouthScore: object.alias,
          pregCheck: false,
        };
        await this.herdMeta.create("mouthscores", data, "mouthscore");
        this.init();
      }
    },
    async saveOrigin(object) {
      if (object.id) {
        const data = { id: object.id, origin: object.alias };
        await this.herdMeta.update("origins", data);
      } else {
        const data = {
          id: "origin_" + this.$utils.guid(),
          origin: object.alias,
          pregCheck: false,
        };
        await this.herdMeta.create("origins", data, "origin");
        this.init();
      }
    },
    async toggleArchiveMouthScore(row) {
      const data = {
        id: row.id,
        archived: !row.archived,
      };
      await this.herdMeta.update("mouthscores", data);
      if (data.archived) this.togglePregCheck(row, false);
    },
    async fetchOrigins() {
      this.headers = [
        {
          align: "start",
          sortable: false,
          text: "Origins",
          value: "alias",
        },
        {
          align: "start",
          sortable: false,
          text: "Wean origins",
          value: "weanOrigin",
        },
      ];
      const response = await this.herdMeta.getMetaOriginsAsync(
        true,
        !this.displayAll,
        false
      );

      response.forEach(async (row, index) => {
        row.alias = row.origin;
        row.editable = this.getAllowedEdition(row);
        row.archived = toBoolean(row.archived);
      });
      this.objects = response;
    },
    async toggleArchiveOrigin(row) {
      const data = {
        id: row.id,
        archived: !row.archived,
      };
      await this.herdMeta.update("origins", data);
      if (data.archived) this.toggleWeanOrigin(row, false);
    },
    async fetchPasturesAndPens() {
      this.headers = [
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("pastureSlashPen"),
          value: "alias",
        },
        {
          align: "start",
          sortable: false,
          text: "Description",
          value: "description",
        },
        {
          align: "start",
          sortable: false,
          text: "Size",
          value: "sizeUnits",
        },
        {
          align: "start",
          sortable: false,
          text: "Latitude",
          value: "latitude",
        },
        {
          align: "start",
          sortable: false,
          text: "Longitude",
          value: "longitude",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("intakeLocation"),
          value: "intakeLocation",
        },
      ];
      const response = await this.herdMeta.getMetaLocationsAsync(
        true,
        !this.displayAll,
        false
      );
      console.log(response);
      response.forEach(async (row, index) => {
        row.alias = row.name;
        row.editable = this.getAllowedEdition(row);
        row.archived = toBoolean(row.archived);

        if (row.intakeLocation === undefined) {
          row.intakeLocation = true; // true by defult as PasturesAndPens.vue does
        }
      });
      this.objects = response;
    },
    async toggleArchivePasturePen(row) {
      const data = {
        id: row.id,
        archived: !row.archived,
      };
      await this.herdMeta.update("locations", data, "location");
      if (data.archived) this.toggleIntakeLocation(row, false);
    },
    async fetchSexes() {
      this.headers = [
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("sexes"),
          value: "alias",
        },
        {
          align: "start",
          sortable: true,
          text: this.getLabelTranslation("gender"),
          value: "gender",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("weanSexes"),
          value: "weanSex",
        },
      ];
      const response = await this.herdMeta.getMetaSexesAsync(
        true,
        !this.displayAll,
        false
      );
      response.forEach(async (row, index) => {
        row.alias = row.sex;
        row.editable = !row.gender || this.getAllowedEdition(row);
        row.weanSex = toBoolean(row.weanSex);
        row.archived = toBoolean(row.archived);
      });
      this.objects = response;
    },
    async saveSex(object) {
      if (object.id) {
        const data = { id: object.id, sex: object.alias };
        await this.herdMeta.update("sexes", data);
      } else {
        const data = { id: "", sex: object.alias };
        await this.herdMeta.create("sexes", data, "sex");
        this.init();
      }
    },
    async toggleArchiveSex(row) {
      const data = {
        id: row.id,
        archived: !row.archived,
      };
      await this.herdMeta.update("sexes", data);
      if (data.archived) this.toggleWeanSex(row, false);
    },
    async fetchTags() {
      this.headers = [
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("Name"),
          value: "alias",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("Tipo"),
          value: "type",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("tags.cost"),
          value: "cost",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("tags.bagCost"),
          value: "bagCost",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("tags.bagQuantity"),
          value: "bagQuantity",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("tags.status"),
          value: "status",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("tags.color"),
          value: "color",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("tags.manufacturer"),
          value: "manufacturer",
        },
        {
          align: "start",
          sortable: false,
          text: this.getLabelTranslation("tags.size"),
          value: "size",
        },
      ];
      const response = await this.herdMeta.getMetaTagTableAsync(
        true,
        !this.displayAll,
        false
      );
      response.forEach(async (row, index) => {
        row.alias = row.name;
        row.editable = this.getAllowedEdition(row);
        row.archived = toBoolean(row.archived);
      });
      this.objects = response;
    },
    toggleArchiveTag(row) {
      const data = {
        id: row.id,
        archived: !row.archived,
      };
      return this.herdMeta.update("tags", data);
    },
    async toggleArchive(row) {
      if (!this.activeDataType.toggleArchive) return;

      const message = row.archived ? "Enable record(s)?" : "Disable record(s)?";
      if (row.enabledVT == null || row.enabledVT == row.archived) {
        row.enabledVT = !row.archived;
      }

      await this.activeDataType.toggleArchive(row);
      await this.fetchData();
    },
    async togglePregCheck(object, pregCheck) {
      const data = { id: object.id, mouthScore: object.alias, pregCheck };
      await this.herdMeta.update("mouthscores", data);
    },
    async toggleWeanOrigin(object, weanOrigin) {
      const data = { id: object.id, origin: object.alias, weanOrigin };
      await this.herdMeta.update("origins", data);
    },
    async toggleIntakeLocation(object, intakeLocation) {
      const data = { id: object.id, name: object.alias, intakeLocation };
      await this.herdMeta.update("locations", data);
    },
    async toggleWeanSex(object, weanSex) {
      const data = { id: object.id, sex: object.alias, weanSex };
      await this.herdMeta.update("sexes", data);
    },
    exportToCSV() {
      if (this.selected.length == 0) {
        // show message
        this.$notify({
          group: "forms",
          text: "Please select at least 1 row.",
          title: "",
          type: "info",
        });
        return;
      }
      const headers = this.headers.filter((header) => header.text !== "Enable");
      const fields = headers.map((header) => header.text);
      const data = this.selected.map((herd) =>
        headers.map(({ value }) => herd[value])
      );
      this.herdMeta.exportToCSV(fields, data, this.activeDataType.title);
    },
    openEditAnimalDialog(objectToEdit) {
      if (this.activeDataType.customRefDialog) {
        const customDialog = this.$refs[this.activeDataType.customRefDialog];
        return customDialog.open(objectToEdit);
      }
      return this.$refs.editAnimalDialog.open(objectToEdit);
    },
    getAllowedEdition(row) {
      const diffTime = new Date() - new Date(row.createdOn);
      const diffHours = diffTime / (1000 * 60 * 60);

      // Allow editing for up to 1 year
      return diffHours >= 0 && diffHours <= 24 * 365;
    },
    async reorderRow(e) {
      const item = e.item;
      const itemId = item.childNodes[1].id;

      const new_idx = e.newDraggableIndex;

      const newArr = this.objects.filter((object) => object.id !== itemId);
      const currentItem = this.objects.find((object) => object.id === itemId);
      newArr.splice(new_idx, 0, currentItem);
      this.objects = newArr.map((item, idx) => {
        item.orderVT = idx;
        return item;
      });

      const promises = this.objects.map((item) => {
        const data = { id: item.id, orderVT: item.orderVT };
        return this.herdMeta.update(item.tableName, data);
      });
      try {
        await Promise.all(promises);
      } catch (error) {
        this.$notify({
          group: "forms",
          type: "error",
          title: "Error",
          text: "An error occurred",
        });
      }
    },
  },
};
</script>
<style scoped>
>>> .display-mode label {
  margin-bottom: 0px;
}
.animal-search {
  max-width: 300px;
}
>>> .theme--light.animal-search fieldset {
  border-color: rgba(0 0 0 / 0.2);
}
>>> .mdi-checkbox-marked.theme--light,
>>> .mdi-minus-box.theme--light {
  color: var(--dark-blue);
}
>>> .v-list-item.v-item--active {
  color: var(--dark-blue);
}
>>> .v-data-table__wrapper {
  overflow-x: auto;
}
>>> .v-data-table__wrapper thead th:not(:first-of-type),
>>> .v-data-table__wrapper thead td:not(:first-of-type) {
  min-width: 100px !important;
}
.theme--dark .data-types-list .v-item--active .v-list-item__title {
  color: white;
}
.no-data-container {
  width: 100%;
}
@media (min-width: 600px) {
  .no-data-container {
    max-width: 20rem;
  }
}
.archive-switch {
  cursor: pointer;
  display: inline-block;
}
.archive-switch .v-input {
  pointer-events: none;
}
</style>
